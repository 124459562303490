<template>
  <div id="menu">
    <div id="listMenu" class="d-flex justify-content-center flex-wrap">
      <div class="box" @click="$router.push('/invoices')">
        <img src="@/assets/icon/medal.svg" alt width="32" height="32" />
        <div class="txt">เช็คคะแนน</div>
      </div>
      <div class="box" @click="$router.push('/rewards')">
        <img src="@/assets/icon/gift.svg" alt width="32" height="32" />
        <div class="txt">แลกของรางวัล</div>
      </div>
      <!-- <div class="box" @click="$router.push('/promotions')">
        <img src="@/assets/icon/truck.svg" alt width="32" height="32" />
        <div class="txt">สินค้าร่วมรายการ</div>
      </div> -->
      <!-- <div class="box" @click="$router.push('/lucky-draws')">
        <div class="txt text-center lucky-sp">
          LUCKY
          <br />SURPRISE
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.loading(false);
  },
};
</script>
<style lang="scss" scoped>
#menu {
  background: rgb(15, 16, 102);
  padding: 10px 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#listMenu {
  align-items: center;
}

.box {
  background: rgb(255, 63, 143);
  color: white;
  border: 1px solid #b1b1b1;
  border-radius: 6px;
  margin: 4px;
  padding: 5px;
  min-width: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  box-shadow: 2px 2px black;
  .txt {
    font-weight: 500;
  }
  .lucky-sp {
    line-height: 20px;
  }
}
</style>